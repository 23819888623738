import React from "react"
import { graphql } from "gatsby"
import Layout from "./layout"
import { GatsbySeo, BlogPostJsonLd } from "gatsby-plugin-next-seo"
import Image from "gatsby-image"
import siteMeta from "@components/site-metadata"

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html, excerpt } = markdownRemark
  const url = siteMeta().siteUrl + frontmatter.path
  const coverImage = frontmatter.image
    ? frontmatter.image.childImageSharp.fluid.src
    : null

  const renderTags = () => {}
  const relatedArticles = []

  return (
    <Layout>
      <GatsbySeo
        title={frontmatter.title}
        description={excerpt}
        canonical={url}
        openGraph={{
          title: frontmatter.title,
          description: excerpt,
          url,
          type: "article",
          article: {
            // publishedTime: "2017-06-21T23:04:13Z",
            // modifiedTime: "2018-01-21T18:04:43Z",
            // expirationTime: "2022-12-21T22:04:11Z",
            // section: "Section II",
            authors: [],
            tags: frontmatter.tags,
          },
          images: [
            {
              url: coverImage,
              // width: 850,
              // height: 650,
              alt: frontmatter.title,
            },
          ],
        }}
        twitter={{
          creator:
            frontmatter.author.social && frontmatter.author.social.twitter,
        }}
      />

      <BlogPostJsonLd
        url={url}
        headline={frontmatter.title}
        images={[coverImage]}
        // posts={[
        //   {
        //     headline: "Post 1",
        //     image: "https://example.com/photos/1x1/photo.jpg",
        //   },
        //   { headline: "Post 2" },
        // ]}
        datePublished={frontmatter.date}
        // dateModified="2015-02-05T09:00:00+08:00"
        authorName={frontmatter.author.name}
        description={frontmatter.excerpt}
      />

      <div className="py-12 bg-white h-full flex lg:pb-40">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          {frontmatter.image && (
            <Image
              fluid={frontmatter.image.childImageSharp.fluid}
              className="mb-4 mx-auto bg-gray-100 relative z-10"
            />
          )}
          <div
            className="pattern-dots-md bg-transparent text-gray-400 h-64 mt-12 mx-auto max-w-sm absolute"
            style={{
              marginTop: -74,
              marginLeft: -120,
              height: 300,
              width: 300,
            }}
          >
            <div
              className="bg-white"
              style={{
                width: 260,
                height: 240,
                marginTop: 64,
                marginLeft: 60,
              }}
            />
          </div>

          <div className="text-black bg-white relative z-10">
            {/*<p className="mt-4 text-xl leading-7 text-gray-500 lg:mx-auto">*/}
            {/*  {excerpt}*/}
            {/*</p>*/}

            {/*<p className="mt-20 mb-6 text-base leading-6 text-gray-400  tracking-wide">*/}
            {/*  <span>{frontmatter.date}</span>*/}
            {/*  <span> --- </span>*/}
            {/*  {frontmatter.categories &&*/}
            {/*    frontmatter.categories.map(category => {*/}
            {/*      return (*/}
            {/*        <span>*/}
            {/*          <a*/}
            {/*            className="text-gray-400 "*/}
            {/*            href={"/blog/category/" + category}*/}
            {/*          >*/}
            {/*            {category}*/}
            {/*          </a>*/}
            {/*          ,{" "}*/}
            {/*        </span>*/}
            {/*      )*/}
            {/*    })}*/}
            {/*</p>*/}

            <h1 className="text-black text-2xl md:text-5xl leading-tight mt-4 md:mt-20">
              {frontmatter.title}
            </h1>

            <div className="text-black mt-4">
              {frontmatter.author && (
                <span>
                  By {/*<img*/}
                  {/*  className="h-4 rounded-full inline-block ml-1 mr-1 -mt-1"*/}
                  {/*  src={frontmatter.author.avatar}*/}
                  {/*/>{" "}*/}
                  <span className="font-semibold">
                    {frontmatter.author.name}
                  </span>
                </span>
              )}
              {frontmatter.author && (
                <span>
                  {" in "}
                  {frontmatter.categories &&
                    frontmatter.categories.map(category => {
                      return (
                        <span key={category}>
                          <a
                            className="underline text-black"
                            href={"/blog/category/" + category}
                          >
                            {category}
                          </a>
                          ,{" "}
                        </span>
                      )
                    })}
                </span>
              )}
            </div>

            <div className="mt-1">
              <span className="uppercase text-sm text-gray-500">
                {frontmatter.date}
              </span>
            </div>

            <div className="pattern-dots-md bg-transparent text-gray-400 h-4 mt-8 mx-auto max-w-xs"></div>

            <article
              className="content bg-white mx-auto text-gray-900 text-left mt-8 text-md leading-loose"
              dangerouslySetInnerHTML={{ __html: html }}
            />

            <div className="pattern-dots-md bg-transparent text-gray-400 h-4 my-12 mx-auto max-w-xs"></div>

            <div className="my-12 flex">
              {/*<div className="w-24 mr-4 -ml-24">*/}
              {/*  */}
              {/*</div>*/}
              <div className="flex-grow text-center md:text-left">
                <img
                  className="rounded-full h-24 w-24 m-auto md:absolute md:-ml-32"
                  src={frontmatter.author.avatar}
                  alt={frontmatter.author.name}
                />
                <p className="text-xl font-semibold">
                  {frontmatter.author.name}
                </p>
                <p className="mt-1 text-md text-gray-500 md:pr-32">
                  {frontmatter.author.bio}
                </p>
              </div>
            </div>
          </div>

          {relatedArticles.length && (
            <div className="pt-12">
              <h4 className="text-black uppercase font-semibold">
                Related Articles
              </h4>
              <ul></ul>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query blogPostQuery($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        image {
          childImageSharp {
            fluid {
              src
              srcSet
              aspectRatio
              sizes
            }
          }
        }
        categories
        language
        author {
          name
          bio
          slug
          avatar
          social {
            twitter
            github
          }
        }
      }
    }
  }
`
